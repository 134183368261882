function isOldChromeVersion(userAgent: string): boolean {
  const match = userAgent.match(/Chrome\/(\d+)/)
  return (
    userAgent.indexOf("Chrome") > -1 &&
    userAgent.indexOf("Mobile") > -1 &&
    match !== null &&
    parseInt(match[1], 10) < 62
  )
}

export { isOldChromeVersion }
